body {
    background: url('../img/apple-blur-book-stack-256520.jpg') no-repeat center bottom fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#loginbox {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 15px;
}
