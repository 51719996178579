#content {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    margin-bottom: 10%;
}

#footer {
    background-color: rgba(255, 255, 255, 0.8);
}

@media only screen and (max-width: 500px) {
    #footer {
        display: none;
    }
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

.react-datepicker-wrapper, .react-datepicker__input-container, .react-datepicker__input-container input {
    border-radius: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.line-height-small {
    line-height: 1;
}

#dailyBookingByClassButton {
    float: right !important;
}
