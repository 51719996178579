#overlayUsersList table {
    color: #000000 !important;
}

#overlayUsersList thead {
    color: #000000 !important;
}

.modal-dialog {
    max-width: none;
}
